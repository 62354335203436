@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
body {
  overflow-x: hidden;
}
@media screen and (min-width: 576px) {
  body {
    overflow-y: hidden;
  }
}
h4,
h5,
h6,
h1,
h2,
h3,
label,
a {
  font-family: "Poppins", sans-serif;
  color: #0039e6;
}
.scroll {
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.navbar a,
span,
div {
  font-family: "Poppins", sans-serif;
}
.container {
  width: 100%;
  margin: 0 auto;
}

#map_container {
  position: relative;
}
#map {
  height: 0;
  overflow: hidden;
  padding-bottom: 50%;
  padding-top: 120px;
  position: relative;
}
